<template>
  <div>
    <h5 class="primary--text fw-700 font-14px text-center mb-6">
      「その他」のコストとは？
    </h5>
    <div class="font-12px text-333 line-height-15">
      <p>
        <span class="fw-700">「その他」のコスト</span>
        とは販売費及び一般管理費（販管費）の中から、食材仕入高、人件費、家賃、PF手数料を除いた総コストのことです。
      </p>
      <p>
        その他コストを毎月で記録することで、月報データに利益・損失（売上ーコスト）や対売上コスト比率をより詳細に表示できるようになります。
      </p>
      <p>
        一般的に、ここでの入力は25万円、30万円、などと大雑把な入力でもかまいません。
      </p>
      <p>その他のコスト一例：交通費、水道光熱費、通信費等</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "OtherCostModal",
};
</script>
